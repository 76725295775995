import client from "../../client";

const Api = {
  getErrorList: "/api/WarehouseService/InWarehouseExceptionInvestigation/GetListForUserPaged",
};

export const getErrorList = ({warehouseId,searchKey,skipCount,maxResultCount,sorting}) => {
  return client.request({
    url: Api.getErrorList,
    data: {warehouseId,searchKey,skipCount,maxResultCount,sorting},
    method: "post",
  });
};

