<template>
  <Content>
    <template #contentTitle>
      {{ $t('menu.inbound_survey') }}
    </template>
    <template v-slot:contentBody="wrap">
      <div class="mb-3">
        <a-row>
          <a-col class="mr-3">
            <a-input style="width: 300px"
                     v-model:value="searchKey"
                     :placeholder="$t('logistics.enter_boxNo_PlanName_number')"
                     allowClear
                     :title="$t('logistics.enter_boxNo_PlanName_number')"
                     @pressEnter="handleSearch"></a-input>
          </a-col>
          <a-col class="mr-3">
            <a-select style="min-width: 250px"
                      v-model:value="selectedCode"
                      :placeholder="$t('warehouse.warehouse_code')"
                      allow-clear
                      :showSearch="true"
                      optionFilterProp="search-key"
                      @change="handleChangeFilter">
              <a-select-option v-for="(item,key) in warehouseCodes"
                               :key="key"
                               :title="`${item.warehouseNo}(${item.warehouseName})`"
                               :search-key="item.warehouseNo + item.warehouseName"
                               :value="item.id">
                {{item.warehouseNo}}({{item.warehouseName}})
              </a-select-option>
            </a-select>
          </a-col>
          <a-col>
            <a-button type="primary"
                      :loading="loading"
                      @click="handleChangeFilter">
              {{ $t('common.search') }}
            </a-button>
          </a-col>
        </a-row>
      </div>
      <!-- 表格 -->
      <a-table :columns="columns"
               :data-source="list"
               :scroll="{ x: 500,y: wrap.contentHeight - 120 }"
               :pagination="false"
               :loading="loading"
               size="small">
        <template #boxNoTitle>{{ $t('warehouse.box_no') }}</template>
        <template #boxNo="{ record }">
          <div>{{ record.boxNo }}</div>
          <div><small>{{ record.selfBoxNo }}</small></div>
        </template>
        <template #inWarehousePlan>
          <span> {{ $t('warehouse.in_warehouse_plan') }} </span>
        </template>
        <template #warehouseCode>
          <span> {{ $t('warehouse.warehouse_code') }} </span>
        </template>
        <template #warehouseType>
          <span> {{ $t('warehouse.warehouse_type') }} </span>
        </template>
        <template #logisticsType>
          <span> {{ $t('logistics.logistics_type') }} </span>
        </template>
        <template #deliveryWay>
          <span> {{ $t('logistics.delivery_way') }} </span>
        </template>
        <template #trackingNumber>
          <span> {{ $t('warehouse.tracking_number') }} </span>
        </template>
        <template #requestTime>
          <span> {{ $t('warehouse.request_time') }} </span>
        </template>
        <template #investigationStatus>
          <span> {{ $t('warehouse.investigation_status') }} </span>
        </template>
        <template #surveyResult>
          <span> {{ $t('warehouse.survey_result') }} </span>
        </template>
        <template #endTime>
          <span> {{ $t('warehouse.end_time') }} </span>
        </template>

        <template #warehouseTypeCustom={record}>
          {{$t($enumLangkey('warehouseType',record.warehouseType))}}
        </template>
        <template #logisticsTypeCustom={record}>
          {{$t($enumLangkey('logisticsWay',record.logisticsType))}}
        </template>
        <template #deliveryWayCustom={record}>
          {{$t($enumLangkey('deliveryWay',record.deliveryWay))}}
        </template>
        <template #investigationStatusCustom={record}>
          {{$t($enumLangkey('surveyStatus',record.investigationStatus))}}
        </template>
        <template #surveyResultCustom={record}>
          <span v-if="record.surveyResult">{{ $t($enumLangkey('productErrorDisposeType',record.surveyResult)) }}</span>
          <span v-else>-</span>
        </template>
        <template #trackingNumberCustom={record}>
          <span v-if="record.trackingNumber">{{ record.trackingNumber }}</span>
          <span v-else>-</span>
        </template>
        <template #requestTimeCustom={record}>
          {{ $filters.utcToCurrentTime(record.requestTime) }}
        </template>
        <template #endTimeCustom={record}>
          {{$filters.utcToCurrentTime(record.endTime)}}
        </template>

      </a-table>
    </template>
    <!-- 分页 -->
    <template #contentFooter>
      <a-row type="flex"
             justify="space-around"
             align="middle"
             class="mb-1">
        <a-col>
          <CPager @handlePage="handlePage"
                  :page-data="pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  onActivated,
} from 'vue';
import {
  Table,
  Select,
  Button,
  Row,
  Col,
  Input,
} from 'ant-design-vue';
import CPager from "../components/CPager.vue";
import Content from '../components/Content.vue';
import { getWarehouses } from '../../api/modules/common/index.js';
import { getErrorList } from '../../api/modules/customer/index.js';

const columns = [
  {
    width: 200,
    fixed: 'left',
    slots: {
      title: 'boxNoTitle',
      customRender: 'boxNo',
    },
  },
  {
    dataIndex: 'inWarehousePlan',
    width: 150,
    slots: {
      title: 'inWarehousePlan',
    },
  },
  {
    dataIndex: 'warehouseCode',
    width: 100,
    slots: {
      title: 'warehouseCode',
    },
  },
  {
    dataIndex: 'warehouseType',
    width: 100,
    slots: {
      title: 'warehouseType',
      customRender: "warehouseTypeCustom",
    },
  },
  {
    dataIndex: 'logisticsType',
    width: 100,
    slots: {
      title: 'logisticsType',
      customRender: "logisticsTypeCustom",
    },
  },
  {
    dataIndex: 'deliveryWay',
    width: 100,
    slots: {
      title: 'deliveryWay',
      customRender: "deliveryWayCustom",
    },
  },
  {
    width: 150,
    slots: {
      title: 'trackingNumber',
      customRender: "trackingNumberCustom",
    },
  },
  {
    width: 150,
    slots: {
      title: 'requestTime',
      customRender: "requestTimeCustom",
    },
  },
  {
    dataIndex: 'investigationStatus',
    width: 150,
    slots: {
      title: 'investigationStatus',
      customRender: "investigationStatusCustom",
    },
  },
  {
    dataIndex: 'surveyResult',
    width: 150,
    slots: {
      title: 'surveyResult',
      customRender: "surveyResultCustom",
    },
  },
  {
    dataIndex: 'endTime',
    width: 150,
    slots: {
      title: 'endTime',
      customRender: "endTimeCustom",
    },
  },
];

export default defineComponent({
  name: "inbound_survey",
  components: {
    ATable: Table,
    CPager,
    Content,
    AButton: Button,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
  },
  setup () {
    const data = reactive({
      list: [],
      loading: false,
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10
      },
      searchKey: null,
      selectedCode: null,
      warehouseCodes: []
    });

    const handlePage = async (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      handleSearch();
    }

    const handleChangeFilter = () => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      handleSearch();
    }

    const handleSearch = async () => {
      data.loading = true;
      let parameter = {
        warehouseId: data.selectedCode,
        searchKey: data.searchKey,
        ...data.pageData
      };
      getErrorList(parameter).then((res) => {
        if (res.result) {
          let { items, totalCount } = res.result;
          data.list = items.map((x, index) => {
            return {
              key: index,
              boxNo: x.boxNo,
              selfBoxNo: x.selfBoxNo,
              inWarehousePlan: x.planNo,
              warehouseCode: x.warehouseNo,
              warehouseType: x.warehouseType,
              logisticsType: x.logisticsType,
              deliveryWay: x.deliveryType,
              trackingNumber: x.trackNo,
              requestTime: x.creationTime,
              investigationStatus: x.investigationStatus,
              surveyResult: x.investigationResult,
              endTime: x.lastModificationTime,
            }
          });
          data.pageData.totalCount = parseInt(totalCount);
        } else {
          data.pageData.totalCount = 0;
        }

        data.loading = false;
      }).catch(() => {
        data.loading = false;
      });
    };

    const funcGetAllWarehouse = () => {
      getWarehouses({}).then((res) => {
        if (res.result) {
          data.warehouseCodes = res.result;
        }
      })
    }

    onMounted(() => {
      funcGetAllWarehouse();
    })

    onActivated(() => {
      handleSearch();
    });

    return {
      columns,
      ...toRefs(data),
      handleSearch,
      handlePage,
      handleChangeFilter
    };
  },
});
</script>
<style lang="less" scoped>
</style>
